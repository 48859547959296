(function() {
	'use strict';

	var $infoBox = $('.js-question');


	// FAQ toggler
	if ( $infoBox.length > 0 ) {
		$infoBox.on('click', function() {
			var $self = $(this),
				$target = $self,
				targetOpened = !!$target.hasClass('expand');

			// Close all boxes
			$infoBox.removeClass('expand');

			// Check current box for toggle
			if (targetOpened) {
				$target.removeClass('expand');
			} else {
				$target.addClass('expand');
			}
		});
	}
})();

// reload page to detect the platform correct
window.onhashchange = function() {
	window.location.reload();
}

// carousel controllers
if ($('.slider-sliderSliNotiSecSm').length > 0) {
	var sliderInfNotiSecMed = document.querySelectorAll('.slider-sliderSliNotiSecSm').forEach(
	function(slider, index){
		tns({
			"container": slider,
			"controlsContainer": document.querySelectorAll('.slider-sliderSliNotiSecSm-controls')[index],
			"preventScrollOnTouch": "force",
			"controlsPosition": "bottom",
			"controls": false,
			"navPosition": "bottom",
			"mouseDrag": true,
			"speed": 400,
			"center": false,
			"slideBy": 1,
			"fixedWidth": 270,
			"gutter": 16,
			"loop": false,
			"responsive": {
				"768": {
					"slideBy": 1,
					"controls": false,
					"fixedWidth": 290,
					"gutter": 32
				},
				"1280": {
					"slideBy": 1,
					"controls": true,
					"fixedWidth": 290,
					"gutter": 64
				},
				"1600": {
					"slideBy": 1,
					"controls": true,
					"fixedWidth": 290,
					"gutter": 64
				}
			}
		})
	})
}

if ($('.slider-sliderSliNotiSecSmRating').length > 0) {
	var sliderInfNotiSecMedRating = tns({
		"container": ".slider-sliderSliNotiSecSmRating",
		"controlsContainer": ".slider-sliderSliNotiSecSmRating-controls",
		"preventScrollOnTouch": "force",
		"controlsPosition": "bottom",
		"controls": false,
		"navPosition": "bottom",
		"mouseDrag": true,
		"speed": 400,
		"center": false,
		"slideBy": 1,
		"fixedWidth": 270,
		"gutter": 16,
		"loop": false,
		"responsive": {
			"768": {
				"slideBy": 1,
				"controls": false,
				"fixedWidth": 290,
				"gutter": 32
			},
			"1280": {
				"slideBy": 1,
				"controls": true,
				"fixedWidth": 290,
				"gutter": 64
			},
			"1600": {
				"slideBy": 1,
				"controls": true,
				"fixedWidth": 290,
				"gutter": 64
			}
		}
	});
}
